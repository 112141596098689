// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

import LocomotiveScroll from 'locomotive-scroll';


// const scroll = new LocomotiveScroll({
//   el: document.querySelector('[data-scroll-container]'),
//   smooth: true,
// });

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

const swiper = new Swiper('.brandsSwiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  slidesPerView: 1,
  spaceBetween: 40,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 50,
    },
  },
});

const testimonials = new Swiper(".testimonialsSwiper", {
  direction: "vertical",
  slidesPerView: 2.5,
  spaceBetween: 30,
  mousewheel: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const servicesSwiper = new Swiper(".servicesSwiper", {
  slidesPerView: "auto",
  spaceBetween: 30,
  // centeredSlides: true,
  grabCursor: true,
});

new kursor({
  type: 4, 
  color: '#8D1EFF',
  removeDefaultCursor: true
});

AOS.init({
  duration: 700
});

// Your app code
console.log(`Hello ${process.env.HELLO}`);

